import dupla from './imagenes/dupla.jpg'
import ivan from './imagenes/ivan.png'
import benja from './imagenes/benja.png'
import commanche from './imagenes/commanche.png'
import budbottleb from './imagenes/budbottleb.jpeg'
import budbottler from './imagenes/budbottler.jpeg'
import budafi from './imagenes/budafi.png'
import actimelgirl from './imagenes/actimelgirl.jpg'
import actimelman from './imagenes/actimelman.jpg'
import actimelafi from './imagenes/actimelafi.png'
import fiatidea from './imagenes/fiatidea.jpg'
import farmacityafi from './imagenes/farmacityafi.jpeg'
import farmacityafiflat from './imagenes/farmacityafiflat.jpeg'
import farmacityafistreet from './imagenes/farmacityafistreet.jpeg'
import warsteiner from './imagenes/warsteiner.jpeg'
import warsteiner2 from './imagenes/warsteiner2.png'
import warsteiner3 from './imagenes/warsteiner3.png'
import warsteinerfly from './imagenes/warsteinerfly.png'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "dupla": dupla,
    "ivan": ivan,
    "benja": benja,
    "commanche": commanche,
    "budbottleb": budbottleb,
    "budbottler": budbottler,
    "budafi": budafi,
    "actimelgirl": actimelgirl,
    "actimelman": actimelman,
    "actimelafi": actimelafi,
    "fiatidea": fiatidea,
    "farmacityafi": farmacityafi,
    "farmacityafiflat": farmacityafiflat,
    "farmacityafistreet": farmacityafistreet,
    "warsteiner": warsteiner,
    "warsteiner2": warsteiner2,
    "warsteiner3": warsteiner3,
    "warsteinerfly": warsteinerfly
}
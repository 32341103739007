import abinbev from './videos/abinbev.mp4'
import ubereats from './videos/ubereats.mp4'
import couples from './videos/famouscouples.mp4'
import actimel from './videos/actimel.mp4'
import cocacola from './videos/cocaarg.mp4'
import elaion from './videos/elaion.mp4'
import farmacity from './videos/farmacity.mp4'
import fiat from './videos/fiat.mp4'
import maniac from './videos/maniac.mp4'
import olx from './videos/olx.mp4'
import ricardito from './videos/ricardito.mp4'
import toro from './videos/toro.mp4'
import warsteiner from './videos/warsteiner.mp4'
import bud from './videos/bud.mp4'
import almamora from './videos/almamora.mp4'
import bonobon from './videos/bonobon.mp4'
import becker from './videos/becker.mp4'


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "abinbev": abinbev,
    "ubereats": ubereats,
    "couples": couples,
    "actimel": actimel,
    "cocacola": cocacola,
    "elaion": elaion,
    "farmacity": farmacity,
    "fiat": fiat,
    "maniac": maniac,
    "olx": olx,
    "ricardito": ricardito,
    "toro": toro,
    "warsteiner": warsteiner,
    "bud": bud,
    "almamora": almamora,
    "bonobon": bonobon,
    "becker": becker,
}
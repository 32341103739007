import React from 'react'
import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles';

const Img = styled('img')({
  width: '100%',
  height: '100%', 
});

export const Imagen = ({url, titulo}) => {
  return (
    <Grid 
      container 
      alignItems="center" 
      justifyContent="center">
        <Img src = {url} alt={titulo}></Img>
    </Grid>
  )
}

Imagen.propTypes = {}

export default Imagen

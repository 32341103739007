import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box, Typography, Stack, IconButton} from '@mui/material'
import { styled } from '@mui/material/styles';
import { BsLinkedin, BsInstagram, BsEnvelope, BsWhatsapp } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const CustomizedTypOran = styled(Typography)`
    font-family: 'Inter';
    color: #1c2dfc;
`;

const AboutPresentation = ({alt, src, nombre, cargo, rol, lin, ig, mail, tel}) => {
  return (
    <Grid>
        <Grid>
            <Typography className='abouttextduplab fontbold' align='left' component="div" mt={1} mb={0}>
                <Box sx={{ lineHeight: 1.2 }}>{nombre}</Box>
            </Typography>
            <CustomizedTypOran className='abouttextdupla' align='left'>
                <Box sx={{ lineHeight: 1.2 }}>{cargo}</Box>
                <Box sx={{ lineHeight: 1.2 }}>{rol}</Box>
            </CustomizedTypOran>
        </Grid>
        <Grid>
            <Typography pt={1} className="linkabout" marginBottom={{ xs:3, sm:0 }} paddingRight={{sm:'8rem'}}>
                Follow me on&nbsp;
                <a className="icon" href={ig}
                        target="_blank" 
                        rel="noopener noreferrer">Instagram</a> 
                &nbsp;or&nbsp;
                <a className="icon" href={lin}
                        target="_blank" 
                        rel="noopener noreferrer">Linkedin</a> 
                &nbsp;for updates. Contact me anytime here&nbsp;
                <a className="icon" href={tel}
                        target="_blank" 
                        rel="noopener noreferrer">+{tel.substring(14)}</a> 
                &nbsp;or here&nbsp;
                <a className="icon" href={mail}
                        target="_blank" 
                        rel="noopener noreferrer">{mail.substring(7)}</a>.
            </Typography>
        </Grid>
    </Grid>
  )
}

AboutPresentation.propTypes = {}

export default AboutPresentation
import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import Navbar from './Navbar'
import Footer from './Footer'

const AppFrame =({ children }) => {

  return (
    <Grid container>
        <Navbar />
        {children}
        <Footer/>
    </Grid>
  )
}

AppFrame.propTypes = {}

export default AppFrame
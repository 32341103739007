import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { Typography } from '@mui/material'
import { styled, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CalibriFont from './../fonts/CalibriFont'
import Box from '@mui/material/Box';

const CustomizedTypography = styled(Typography)`
  font-family: 'Inter';
  color: #1c2dfc;
  font-weight: 400;
`;

const Footer = props => {
  return (
    <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        pt={20} pb={3}
        >
          <CustomizedTypography variant="h1" align='left' component="div">
              <Box>i:b</Box>
          </CustomizedTypography>
    </Grid>
  )
}

Footer.propTypes = {}

export default Footer
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { MdExpandMore } from 'react-icons/md';
import { Grid } from '@mui/material';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';

export default function ControlledAccordions() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const themeh3 = createTheme();

  themeh3.typography.h3 = {
    fontFamily: 'Inter',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.2,
    color: '#1c2dfc',
    '@media (min-width:600px)': {
      fontSize: '1.3rem',
    },
    [themeh3.breakpoints.up('md')]: {
      fontSize: '2rem',
    },
  };

  return (
    <Grid>
      <ThemeProvider theme={themeh3}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant='h3' className='fontbold' sx={{ width: '33%', flexShrink: 0 }}>
            Agency experience
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography variant='h3' className='fontbold' sx={{ lineHeight: 1.1 }}>2018-today</Typography>
            <Typography variant='h3' pb={2} sx={{ lineHeight: 1.2 }}>Freelance Creative Directors</Typography> 
            <Typography variant='h3' className='fontbold' sx={{ lineHeight: 1.1 }}>2016-2018</Typography>
            <Typography variant='h3' sx={{ lineHeight: 1.1 }}>Dhélet VMLY&R (WPP)</Typography>
            <Typography variant='h3' sx={{ lineHeight: 1.1 }}>Role: Creative Directors</Typography>
            <Typography variant='h3' pb={2} sx={{ lineHeight: 1.1 }}>Clients: Movistar (Telefónica), Actimel, Activia (Danone), Coca-Cola</Typography>
            <Typography variant='h3' className='fontbold' sx={{ lineHeight: 1.1 }}>2015-2016</Typography>
            <Typography variant='h3' sx={{ lineHeight: 1.1 }}>Leo Burnett Argentina</Typography>
            <Typography variant='h3' sx={{ lineHeight: 1.1 }}>Role: Creative Directors</Typography>
            <Typography variant='h3' pb={2} sx={{ lineHeight: 1.1 }}>Clients: Fiat, Warsteiner, Samsung, Arcor</Typography>
            <Typography variant='h3' className='fontbold' sx={{ lineHeight: 1.1 }}>2014-2015</Typography>
            <Typography variant='h3' sx={{ lineHeight: 1.1 }}>Ponce Buenos Aires (MullenLowe)</Typography>
            <Typography variant='h3' sx={{ lineHeight: 1.1 }}>Role: Sr. Creatives</Typography>
            <Typography variant='h3' pb={2} sx={{ lineHeight: 1.1 }}>Clients: Personal (Telecom), Axe, Rexona (Unilever), Awafrut, Glaciar (mineral water)</Typography>
            <Typography variant='h3' className='fontbold' sx={{ lineHeight: 1.1 }}>2009-2014</Typography>
            <Typography variant='h3' sx={{ lineHeight: 1.1 }}>Young & Rubicam Argentina</Typography>
            <Typography variant='h3' sx={{ lineHeight: 1.1 }}>Role: Sr. Creatives</Typography>
            <Typography variant='h3' sx={{ lineHeight: 1.1 }}>Clients: Coca-Cola, Directv, Visa, Danone, Quilmes (AB InBev), Movistar (Telefónica), OLX, Farmacity, Banco Galicia, UNHCR, Cencosud, among many others.</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
            <Typography variant='h3' className='fontbold' sx={{ width: '33%', flexShrink: 0 }}>
              Freelance experience
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='h3'>
              VMLY&R / AFRICA Brazil / MCCANN Italy / MCCANN Miami / Mercado MCCANN / GREY Argentina / RGA Argentina / HAVAS Buenos Aires / DDB Paris / David Buenos Aires / The Martin Agency / LEO BURNETT Buenos Aires / BBDO Argentina / GUT Buenos Aires / ISLA república / The Community Miami / Prana / Hello Mexico / Lucky Generals NY / Fearless NY / Santa Clara Brazil / 180 Amsterdam / WUNDERMAN THOMPSON Mexico / PUBLICIS GROUPE Hamburg
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography variant='h3' className='fontbold' sx={{ width: '33%', flexShrink: 0 }}>Brands</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='h3'>
            Movistar (Telefónica) / Coca-Cola / Gatorade / Amazon / Ab InBev / Budweiser / Miller / Visa debit / YPF (Argentina’s national petroleum company) / Peugeot / Renault / Modelo (Mexican beer) / Banco Nación (Argentina’s national bank) / Warsteiner / Danone (Activia, Actimel) / Entel (Biggest telco from Chile) / Clorox / Shell / UBER / Corona (Mexican beer) / Becker (Chilean beer) / Schar / Mastercard / WeWork / AMEX / JAC motors / Skol (Brazilian beer) / Alma Mora wines / Toro wines / Ualá (fintech unicorn) / Mercado Libre / 7Up / Lays
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography variant='h3' className='fontbold' sx={{ width: '33%', flexShrink: 0 }}>
            Recommendations
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='h3' sx={{ lineHeight: 1.2}} pb={3}>You can ask for recommendations from people that have worked with us.</Typography>
          <Typography variant='h3' sx={{ lineHeight: 1.2 }}> Martin Mercado / CCO Mercado Mccann </Typography>
          <Typography variant='h3' sx={{ lineHeight: 1.2 }}> Diego Medvedocki / President Grey Latam </Typography>
          <Typography variant='h3' sx={{ lineHeight: 1.2 }}> Dani Minaker y Patan Tarazaga / CCO Wunderman Thompson Latam </Typography>
          <Typography variant='h3' sx={{ lineHeight: 1.2 }}> Nino Goldberg / CCO VMLY&R Latam </Typography>
          <Typography variant='h3' sx={{ lineHeight: 1.2 }}> Rodrigo Grau / Co-Founder ISLA república </Typography>
          <Typography variant='h3' sx={{ lineHeight: 1.2 }}> Maxi Itzkoff / Co-Founder Slap Global </Typography>
          <Typography variant='h3' sx={{ lineHeight: 1.2 }}> Matias Menendez / ECD Africa Brazil </Typography>
          <Typography variant='h3' sx={{ lineHeight: 1.2 }}> Joaquín Cubría / CCO & Partner of GUT (President of the Argentinian Creative Circle) </Typography>
          <Typography variant='h3' sx={{ lineHeight: 1.2 }}> Fabio Mazzia / CCO Publicis Argentina </Typography>
          <Typography variant='h3' sx={{ lineHeight: 1.2 }}> Fernando Bellotti / President Leo Burnett Buenos Aires </Typography>
          <Typography variant='h3' sx={{ lineHeight: 1.2 }}> Carlos Perez / MMA Global BBDO </Typography>
          <Typography variant='h3' sx={{ lineHeight: 1.2 }}> Haydee & Maria / Our moms </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography variant='h3' className='fontbold' sx={{ width: '33%', flexShrink: 0 }}>Awards</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='h3'>
              Cannes Lions (silver, bronze, shortlist), DIENTE Argentinian Creative Circle (gold, silver, bronze) EFFIE awards (gold, silver), Ojo de Iberoamérica (GP, gold, silver, bronze), FIAP (gold, silver), El Sol Spain (silver, bronze), LIA awards (gold), New York Festival (silver, bronze), Wave Brazil (gold, silver, bronze), Lápiz de Platino (GP, gold), Martin Fierro (best tv commercial of Argentina), Adweek, Gunn Report, Bestadsontv, Luerzers Archive book
          </Typography>
        </AccordionDetails>
      </Accordion>
      </ThemeProvider>
    </Grid>
  );
}

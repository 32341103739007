
import {
  createTheme
} from '@mui/material/styles';

import Calibrieot from './calibri.eot'
import Calibrittf from './calibri.ttf'
import Calibriwoff from './calibri.woff'
import Calibriwoff2 from './calibri.woff2'

const CalibriFont = createTheme({
  typography: {
    fontFamily: 'Calibri',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Calibri';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Calibri'),
          url(${Calibrieot}),
          url(${Calibrittf}) format('truetype'),
          url(${Calibriwoff}) format('woff'),
          url(${Calibriwoff2}) format('woff2'),;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

export default CalibriFont;
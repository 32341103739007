import {React} from 'react';
import PropTypes from 'prop-types';
import AppFrame from './../components/AppFrame';
import { Grid } from '@mui/material'
import AboutPresentation from './AboutPresentation';
import ImagenList from './../img/ImagenList';
import { Typography } from '@mui/material';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from './AboutAcordion';

const CustomizedGird = styled(Grid)`
background-color: #ededed;
`;

const themeh3 = createTheme();

themeh3.typography.h3 = {
  fontFamily: 'Inter',
  fontSize: '1.1rem',
  fontWeight: 400,
  lineHeight: 1.2,
  color: '#1c2dfc',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [themeh3.breakpoints.up('md')]: {
    fontSize: '2.3rem',
  },
};


const dupla = [
    {     
      alt: 'Ivan Zimmermann',
      src: ImagenList.ivan,
      nombre: 'Ivan Zimmermann',
      cargo: 'Creative Director',
      rol: 'Art Director',
      lin: 'https://linkedin.com/in/ivan-zimmermann',
      ig: 'https://www.instagram.com/ivanzimmmm/?hl=es',
      mail: 'mailto:ivanzimmermann@gmail.com',
      tel: 'https://wa.me/34617288834',
    },
    {
        alt: 'Benjamin Tornquist',
        src: ImagenList.benja,
        nombre: 'Benjamin Tornquist',
        cargo: 'Creative Director',
        rol: 'Copywriter',
        lin: 'https://linkedin.com/in/benjamín-tornquist-22728099',
        ig: 'https://www.instagram.com/benjamintor/?hl=es',
        mail: 'mailto:benjatornquist@gmail.com',
        tel: 'https://wa.me/5491140470911',
    }
]

const AboutPage = props => {

  return (
    <AppFrame>
        <Box
          component="img"
          sx={{
              margin: 'auto 0',
              display: 'block',
              maxWidth: '90%',
              maxHeight: '100%',

          }}
          alt= 'Ivan Zimmermann + Benjamin Tornquist'
          src={ImagenList.dupla}
          marginTop={{ xs: 6, sm: 6, md: 10 }}  
          paddingX={{ xs: 3, sm: 6, md: 10 }} 
          />
        <CustomizedGird container paddingX={{ xs: 3, sm: 6, md: 10 }} paddingTop={{ xs: 3, md: 0}} spacing={{sm: 3}}>
        {dupla
            ? dupla.map((d, i) => (
                <Grid 
                  key={`${d.titulo}-${i}`}
                  item
                  xs={12} sm={6}
                  >
                    <AboutPresentation alt={d.alt} src={d.src} nombre={d.nombre} cargo={d.cargo} rol={d.rol} lin={d.lin} ig={d.ig} mail={d.mail} tel={d.tel} /> 
                 </Grid>  
                ))
            : 'Loading...'}
        </CustomizedGird>
        <CustomizedGird container paddingX={{ xs: 3, sm: 6, md: 10 }} paddingY={{ xs: 3, sm: 6, md: 10 }}>
                <Accordion/>
        </CustomizedGird>
        <CustomizedGird container paddingX={{ xs: 3, sm: 6, md: 10 }}>
                <ThemeProvider theme={themeh3}>
                    <Typography variant='h3'>
                        <Box paddingBottom={{ xs: 2, sm: 4 }}>We were both born the same year, the same month, and only one day apart. Ivan was born on July 6th and Benjamin on July 7th. That gave us an edge over the rest of the creative duos in the world. Well, that and because we love doing this.</Box>
                        <Box paddingBottom={{ xs: 2, sm: 4 }}>We started working together as a creative duo in 2008 at Young & Rubicam Buenos Aires. We worked there for 5 years with Martin Mercado as ECD. Young & Rubicam became agency of the year for 4 consecutive years and won many creative pitches and awards from major creativity festivals.</Box>
                        <Box paddingBottom={{ xs: 2, sm: 4 }}>In 2013 we spent one year in Ponce Buenos Aires working mostly on global projects. And one year later we moved to Leo Burnett as Creative Directors, where we developed campaigns for clients such as Warsteiner, Fiat, and Arcor. That year Leo Burnett also became agency of the year at the Lápiz de Platino Festival and won several awards for different brands.</Box>
                        <Box paddingBottom={{ xs: 2, sm: 4 }}>In 2016 we joined Dhélet VMLY&R to take the creative direction of Movistar (the biggest telco from Argentina). During that time we produced some of the most audacious work for the brand. We also participated in numerous global pitches from all WPP offices in the world. And there we started to like working for multicultural markets.</Box>
                        <Box paddingBottom={{ xs: 2, sm: 4 }}>Today, we are a freelance Global Creative team working remotely for agencies from all over the world. And we always turn on our cameras when we are on a call. That’s our policy.</Box>
                    </Typography>
                </ThemeProvider>  
        </CustomizedGird>
    </AppFrame>
  )
}

AboutPage.propTypes = {}

export default AboutPage

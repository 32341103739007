import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { Grid } from '@mui/material'

const Video = ({urlvideo}) => {

  return (
    <Grid 
      container 
      alignItems="center" 
      justifyContent="center">
        <ReactPlayer 
            url= {urlvideo}
            className='react-player'          
            playing
            muted
            loop
            width="100%"
            height="100%"
            playsinline
        />
    </Grid>
  )
}

Video.propTypes = {}

export default Video
import React, {useState, useEffect} from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import AboutPage from './components/AboutPage';
import MainPage from './components/MainPage'
import WorkPage from './components/WorkPage';

function App() {
  return (
    <Router>
        <Routes>
            <Route exact path="/" element={<MainPage/>}/>
            <Route path="/about" element={<AboutPage/>}/>
            <Route path="/work" element={<WorkPage/>}/>
        </Routes>
    </Router>
  );
}

export default App;

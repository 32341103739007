import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { Grid } from '@mui/material'

const VideoWork = ({urlvideo}) => {

  return (
    <Grid container className='player-wrapper'>
        <ReactPlayer 
            url= {urlvideo}
            className='react-playeri'          
            controls
            width='100%'
            height='100%'
        />
    </Grid>
  )
}

VideoWork.propTypes = {}

export default VideoWork
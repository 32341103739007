import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Link, Typography} from '@mui/material'
import Video from './Video'
import Imagen from './Imagen'
import VideoList from './../vids/VideoList'
import ImagenList from './../img/ImagenList'
import { Link as RouterLink} from 'react-router-dom'
import { styled } from '@mui/material/styles';

const CustomTypOb = styled(Typography)`
    font-family: 'Inter';
    color: #1c2dfc;
    font-weight: 700;
`;

const workabinbev = {
    titulo: 'AB INBEV: Hairy',
    desc: 'In Argentina, due to constant inflation, it’s more and more common to hear that people don’t have money at the end of the month. The brief was actually the launch of a discount platform (“Second half”) which is activated in the second half of each month. And the challenge was how to communicate this without feeling like another promo and talking to the audience in a sense they can relate to. That’s when "Peludo" (Hairy) came out, which in Argentina we all use to refer to an unfavorable situation, as in this case, not being able to buy the products and brands we want. “Hairy” was born, a character that deprives you of being able to buy everything you want, until now.',
    creat: 'Creative Agency: Draftline BUE / Ivan Zimmermann & Benjamin Tornquist (Freelance Creatives)',
    prod: 'Production Company: Landia / Dir. Francisco Colombatti',
    vids: ['https://vimeo.com/763514289'],
    links:['https://www.lbbonline.com/news/drafline-bues-2nd-half-platform-brings-a-happy-ending-to-the-month', 'https://www.adlatina.com/publicidad/preestreno-cerveceria-y-malteria-quilmes-draftline-y-una-plataforma-para-cuando-esta-peludo', 'https://www.latinspots.com/sp/noticia/draftline-bue-y-quilmes-presentan-peludo/63214'],
}
const workubereats = {
    titulo: 'UBER EATS: It’s everything',
    desc: 'Uber Eats is the most popular food delivery app. But we had to communicate a new big feature. From now on, in addition to food, you can also order supermarket supplies, pharmacy products, and beverages of all kinds. In other words, you can get anything. That’s why we wanted to make it clear that whatever the situation could turn into, in Uber Eats you can order anything. Because Uber Eats it’s everything.',
    creat: 'Creative Agency: Mercado Mccann / Ivan Zimmermann & Benjamin Tornquist (Freelance Creative Directors)',
    prod: 'Production Company: Ladoble / Dir. Esteban Sapir',
    vids: ['https://vimeo.com/762392519', 'https://vimeo.com/762392353', 'https://vimeo.com/762392206'],
}
const workcocacola = {
    titulo: 'COCA-COLA: You were born here',
    desc: 'In a difficult year for the country (happening more and more often in Argentina), Coca-Cola wanted to give a positive message people could identify with. Reverting the general feeling of resignation, "You were born here..." revalues all the positive things that being Argentine has, and rescues those most difficult moments that bring those feelings out in each one of us.',
    creat: 'Creative Agency: GREY Argentina / Ivan Zimmermann & Benjamin Tornquist (Freelance Creative Directors)',
    prod: 'Production Company: PRIMO / Dir. Gustavo Taretto',
    vids: ['https://vimeo.com/515450024'],
    links:['http://www.latinspots.com/sp/noticias/detalle/48421/te-toc-nacer-ac-el-mensaje-positivo-de-coca-cola-y-grey-argentina', 'https://www.reasonwhy.es/actualidad/campanas/grey-argentina-campana-coca-cola-te-toco-nacer-aca', 'https://www.cocacoladeargentina.com.ar/novedades/historia--_te-toco-nacer-aca--la-publicidad-que-apela-a-la-argentinidad-y0', 'https://www.adlatina.com/publicidad/“te-tocó-nacer-acá”-lo-nuevo-de-grey-argentina-para-coca-cola'],

}
const workbud = {
    titulo: 'BUDWEISER: We brew birra',
    desc: 'Budweiser needed to relaunch their brand in Argentina and wanted to talk about their unique elaboration process. With the rise of craft beers in the local market, we decided to explain why they are different – and created a concept by mixing something technical like “Brew” with something very Argentine, like “Birra”, (a word used to refer to beer in a colloquial way), all with the voice of Barry White attesting that “We don’t make beer, we brew it.”',
    creat: 'Creative Agency: AFRICA / Ivan Zimmermann & Benjamin Tornquist (Freelance Creative Directors)',
    prod: 'Voice Over: Seba Costa',
    vids: ['https://vimeo.com/482718562', 'https://vimeo.com/482716990', 'https://vimeo.com/482717773'],
    imgs: [ImagenList.budbottleb, ImagenList.budbottler, ImagenList.budafi],
    links:['https://www.adlatina.com/publicidad/una-campaña-en-la-argentina-creada-en-brasil-por-una-agencia-llamada-africa-y-con-un-eslogan-que-mezcla-inglés-y-lunfardo', 'https://www.dossiernet.com.ar/articulo/we-brew-birra-la-campana-de-africa-argentina-para-budweiser/18636', 'http://www.latinspots.com/sp/noticia/africa-argentina-crea-su-primer-trabajo-para-budweiser/49741'],
}
const worktoro = {
    titulo: 'TORO WINE: Paddlers',
    desc: 'A boxed wine, mixed with soda and served in a pint glass. Not very sexy for an advertisement. But we decided to rescue its essence: a wine consumed mainly by workers. With a campaign that positioned this wine as an emblem of the Argentine working-class table, speaking directly to those who have to try twice as hard. A wine made by “laburantes” for “laburantes” (an Argentine word for tough workers).',
    creat: 'Creative Agency: Leo Burnett Buenos Aires / Ivan Zimmermann & Benjamin Tornquist (Freelance Creative Directors)',
    prod: 'Production Company: Rebolución / Dir. Marcelo Burgos',
    vids: ['https://vimeo.com/515455746'],
    links:['https://www.adlatina.com/articulo.php?slug=/publicidad/“remadores”-lo-nuevo-de-leo-burnett-para-vino-toro', 'http://www.latinspots.com/sp/noticia/remadores-lo-nuevo-de-rebolucion-para-toro/48297', 'https://www.dossiernet.com.ar/articulo/vino-toro-y-leo-burnett-remadores-del-lapiz-de-oro/17637', 'https://infonegocios.info/infopublicidad/vino-toro-relanza-su-marca-con-remadores-by-leo-burnett', 'https://www.iprofesional.com/marketing/276922-empresa-plan-trabajo-Remadores-la-campana-de-vino-Toro-que-apela-al-espiritu-del-trabajador-argentino'],
}
const workalma = {
    titulo: 'ALMA MORA: Love is inevitable',
    desc: 'Alma Mora is a wine whose communication always talk about love and couples. Soul mates. However, on the other side there are many single people who still believe in love. How do we keep talking about love when so many people are still looking for it? By encouraging them to meet someone. To never stop looking for it.',
    creat: 'Creative Agency: La Comunidad / Ivan Zimmermann & Benjamin Tornquist (Freelance Creative Directors)',
    prod: 'Production Company: Pantera&Co / Dir. Wacho – Edu Braun y Justo Dell Acqua',
    vids: ['https://vimeo.com/719074253'],
    links:['https://dossiernet.com.ar/articulo/nueva-campana-de-la-comunidad-para-alma-mora/28682'],
}
const workactimel = {
    titulo: 'ACTIMEL: Wake up, shake up',
    desc: 'We found that lots of people shake Actimel’s bottle before drinking it for no logical reason, but they do it anyway. We turned that insight into a campaign, explaining logically that the first shake goes through your body, waking you up and getting you ready for the rest of the day.',
    creat: 'Creative Agency: Dhélet VMLY&R / Ivan Zimmermann & Benjamin Tornquist (Creative Directors)',
    prod: 'Production Company: Out-sider / Dir. Bart Timmer',
    vids: ['https://vimeo.com/482715889', 'https://vimeo.com/482716780', 'https://vimeo.com/482716330', 'https://vimeo.com/482716151'],
    imgs: [ImagenList.actimelgirl, ImagenList.actimelman, ImagenList.actimelafi],
}
const workypf = {
    titulo: 'YPF ELAION AURO: Safe and trust',
    desc: 'For the launch of YPF’s premium lubricant, the challenge was to find an insight, a consumer truth that most drivers could identify with. But not to every car owner, just the ones who trully loves their cars. With something as difficult to show as the internal care of the car: its engine.',
    creat: 'Creative Agency: ISLA Buenos Aires / Ivan Zimmermann & Benjamin Tornquist (Freelance Creative Directors)',
    prod: 'Production Company: Agosto - Labhouse / Dir. Luisa Kracht',
    vids: ['https://vimeo.com/819239888'],
    links:['https://www.adlatina.com/publicidad/preestreno-isla-buenos-aires-e-ypf-brindan-mayor-cuidado-a-los-motores', 'http://www.latinspots.com/sp/noticia/isla-buenos-aires-e-ypf-desarrollan-el-lanzamiento-de-elaion-auro-/61675', 'https://dossiernet.com.ar/articulo/isla-buenos-aires-e-ypf-lanzan-nueva-campana/28393'],
}
const workmrichar = {
    titulo: 'MOVISTAR: Little Richard',
    desc: 'All the affection of a mother shown with a hand-knit sweater so that her mountaineer son will be protected from the cold. And all that love comes back in a video call from up on the mountain, no matter how ridiculous it looks.',
    creat: 'Creative Agency: Dhélet VMLY&R / Ivan Zimmermann & Benjamin Tornquist (Creative Directors)',
    prod: 'Production Company: Landia / Dir. Maxi Blanco',
    vids: ['https://vimeo.com/482721305'],
    links:['https://www.dossiernet.com.ar/articulo/movistar-presenta-ricardito-su-nueva-campana-por-el-dia-de-la-madre-creada-por-dhelet-yr/13487', 'https://www.luerzersarchive.com/en/magazine/commercial-detail/telefonica-movistar-66652.html', 'https://www.lbbonline.com/news/movistar-releases-heartwarming-spot-little-richard-to-celebrate-mothers-day', 'https://www.totalmedios.com/nota/32900/movistar-lanza-ricardito-su-campana-por-el-dia-de-la-madre'],
}
const workmmaniac = {
    titulo: 'MOVISTAR: Maniac',
    desc: 'Michael Sambello’s classic ’80s hit played by a band of musicians who had neither the instruments nor the Flashdance references, but did have credit on their smartphones to Google the chords and play a very singular version of this famous song.',
    creat: 'Creative Agency: Dhélet VMLY&R / Ivan Zimmermann & Benjamin Tornquist (Creative Directors)',
    prod: 'Production Company: Ladoble / Dir. Esteban Sapir',
    vids: ['https://vimeo.com/482720263', 'https://vimeo.com/482722752'],
    links:['https://www.totalmedios.com/nota/32255/dhelet-y-r-y-movistar-lanzan-una-nueva-campana-de-la-promo-multiplicate', 'https://www.mercadonegro.pe/publicidad/campanas/movistar-lanza-maniac-bamboleo/', 'https://www.adlatina.com/campañas/maniacbamboleo'],
}
const workmcommanche = {
    titulo: 'MOVISTAR: Commanche comeback',
    desc: 'Commanche was one of the most iconic cumbia groups of the ’90s in Argentina. 26 years later they got together again on a terrace, just like The Beatles once did. The campaign had such an impact that the song reached the top 50 on Spotify for two weeks. And more importantly, the band was hired to perform at hundreds of weddings and bar mitzvahs.',
    creat: 'Creative Agency: Dhélet VMLY&R / Ivan Zimmermann & Benjamin Tornquist (Creative Directors)',
    prod: 'Production Company: Landia / Dir: Maureen Hufnagel',
    vids: ['https://vimeo.com/485673916', 'https://vimeo.com/486142599'],
    imgs: [ImagenList.commanche],
    links:['https://www.dossiernet.com.ar/articulo/commanche-lo-nuevo-de-dhelet-y-r-para-movistar/14067', 'https://insiderlatam.com/movistar-reune-al-grupo-commanche/', 'http://www.latinspots.com/sp/noticia/dhlet-y-movistar-bailan-con-commanche/45609', 'https://www.adlatina.com/campañas/cómo-te-lo-digo-commanche', 'http://www.marketersbyadlatina.com/articulo/4631-movistar-wunderman-y-dhélet-y%26r-“una-canción-pegadiza-logró-todo”'],
}
const workwarsteiner = {
    titulo: 'WARSTEINER: Horse. The worst advertising ever',
    desc: 'We created an annoying ad on purpose. Then we launched another version responding to haters. An interactive brand manifesto that trolled our own fans. Why would a brand do that? Because if you like it, fine.',
    creat: 'Creative Agency: Leo Burnett Buenos Aires / Ivan Zimmermann & Benjamin Tornquist (Creative Directors)',
    prod: 'Production Company: Agosto / Dir: Diego Irigoyen',
    vids: ['https://vimeo.com/485665573', 'https://vimeo.com/485665987', 'https://vimeo.com/485666782'],
    imgs: [ImagenList.warsteiner, ImagenList.warsteiner2, ImagenList.warsteiner3, ImagenList.warsteinerfly],
    links:['https://www.dossiernet.com.ar/articulo/commanche-lo-nuevo-de-dhelet-y-r-para-movistar/14067https://www.adweek.com/brand-marketing/warsteiner-created-an-annoying-ad-then-launched-another-version-responding-to-the-haters/', 'https://es.adforum.com/creative-work/ad/player/34565156/horse/warsteiner', 'https://www.adlatina.com/articulo.php?slug=/publicidad/“caballo”-preestreno-de-leo-burnett-argentina-para-warsteiner', 'http://www.latinspots.com/sp/noticias/detalle/46380/leo-burnett-argentina-lanza-la-segunda-parte-de-su-campaa-para-cerveza-warsteiner', 'https://www.dossiernet.com.ar/articulo/nueva-campana-de-leo-burnett-argentina-para-warsteiner/14814', 'https://infonegocios.info/infopublicidad/warsteiner-responde-malos-comentarios-con-un-nuevo-comercial'],
}
const workolx = {
    titulo: 'OLX: How much?',
    desc: 'OLX was launching their services in Argentina and needed people to adopt the habit of selling what they no longer use. We decided to show how easy it is to publish those things using the app, and how valuable those things can be for someone else, though you may think it is worth nothing.',
    creat: 'Creative Agency: Young&Rubicam Buenos Aires / Ivan Zimmermann & Benjamin Tornquist (Creative Directors)',
    prod: 'Production Company: Ladoble / Dir. Esteban Sapir & Ignacio Gabriel',
    vids: ['https://vimeo.com/515455104', 'https://vimeo.com/515453878', 'https://vimeo.com/515454491'],
    links:['http://www.latinspots.com/sp/noticia/yr-argentina-y-olx-lanzan-cunto-vale/32860', 'https://www.totalmedios.com/nota/21737/young-presenta-la-campana-completa-de-olx-cuanto-vale'],
}
const workfarmacity = {
    titulo: 'FARMACITY: Health is also contagious',
    desc: 'Farmacity is the largest pharmacy chain store in Argentina that had never had an advertising campaign. We created the concept "health is also contagious," centered on stories between customers and pharmacists that gives you more than just a pack of pills or cough syrup.',
    creat: 'Creative Agency: Young&Rubicam Buenos Aires / Ivan Zimmermann & Benjamin Tornquist (Creative Directors)',
    prod: 'Production Company: Fight Films / Dir. Adriana Laham',
    vids: ['https://vimeo.com/515451341','https://vimeo.com/515452293'],
    imgs: [ImagenList.farmacityafi, ImagenList.farmacityafiflat, ImagenList.farmacityafistreet],
    links:['https://www.totalmedios.com/nota/17657/la-salud-tambien-se-contagia-la-campana-de-y-r-para-farmacity','http://www.latinspots.com/sp/noticia/farmacity-contagia-salud-con-la-creatividad-de-yr/29189', 'https://infonegocios.info/infopublicidad/aparecio-la-primera-campana-de-y-r-para-farmacity', 'https://www.dossiernet.com.ar/articulo/pre-estreno-de-la-nueva-campana-de-farmacity-creada-por-young/1034'],
}
const workbonobon = {    
    titulo: 'BONOBON: Advice',
    desc: 'An iconic chocolate for Argentines positioned as a candy to give as a gift instead of personal consumption. This campaign tries to reverse this behavior with arguments and advice from those who know the least about sharing: kids.',
    creat: 'Creative Agency: Leo Burnett Buenos Aires / Ivan Zimmermann & Benjamin Tornquist (Creative Directors)',
    prod: 'Production Company: Hachiko / Dir. Maureen Hufnagel',
    vids: ['https://vimeo.com/485673498'],
    links:['https://www.dossiernet.com.ar/articulo/consejo-nueva-campana-de-leo-burnett-para-bon-o-bon/8292', 'http://www.latinspots.com/sp/noticia/leo-burnett-da-consejos-para-bon-o-bon/40621'],
}


const trabajos = [
    {
        marca: "AB INBEV",
        titulo: 'Hairy',
        desc_marca: 'Ab InBev',
        desc: 'created a lovely imaginary character hated by a whole country.',
        url: VideoList.abinbev,
        tipo: 'video',
        act: workabinbev,
    },
    {
        marca: "UBER EATS",
        titulo: 'It’s everything',
        desc_marca: 'Uber Eats',
        desc: 'started delivering more than food.',
        url: VideoList.ubereats,
        tipo: 'video',
        act: workubereats,
    },
    {
        marca: "COCA-COLA",
        titulo: 'You were born here',
        desc_marca: 'Coca-Cola',
        desc: 'became a 60” viral spreading hope during a huge crisis.',
        url: VideoList.cocacola,
        tipo: 'video',
        act: workcocacola,
    },
    {
        marca: "BUDWEISER",
        titulo: 'We brew birra',
        desc_marca: 'Budweiser',
        desc: 'used Barry White to sing about their boring RTBs.',
        url: VideoList.bud,
        tipo: 'video',
        act: workbud,
    },
    {
        marca: "ACTIMEL",
        titulo: 'Wake up, shake up',
        desc_marca: 'Actimel',
        desc: 'made everyone shake their little bottles for no reason at all.',
        url: VideoList.actimel,
        tipo: 'video',
        act: workactimel,
    },
    {
        marca: "YPF ELAION AURO",
        titulo: 'Safe and trust',
        desc_marca: 'YPF',
        desc: 'made you remember the worst part of having a car.',
        url: VideoList.elaion,
        tipo: 'video',
        act: workypf,
    },
    {
        marca: "MOVISTAR",
        titulo: 'Little Richard',
        desc_marca: 'Movistar',
        desc: 'homaged one lucky mom.',
        url: VideoList.ricardito,
        tipo: 'video',
        act: workmrichar,
    },
    {
        marca: "MOVISTAR",
        titulo: 'Maniac',
        desc_marca: 'Movistar',
        desc: 'did a weird version of Michael Sambello’s “Maniac”.',
        url: VideoList.maniac,
        tipo: 'video',
        act: workmmaniac,
    },
    {
        marca: "WARSTEINER",
        titulo: 'Worst ad ever',
        desc_marca: 'Warsteiner',
        desc: 'trolled their own followers.',
        url: VideoList.warsteiner,
        tipo: 'video',
        act: workwarsteiner,
    },
    {
        marca: "VINO TORO",
        titulo: 'Paddlers',
        desc_marca: '',
        desc: 'a low-price wine talked to its true audience for the first time.',
        url: VideoList.toro,
        tipo: 'video',
        act: worktoro,
    },
    {
        marca: "ALMA MORA WINE",
        titulo: 'Love is inevitable',
        desc_marca: 'Alma Mora',
        desc: 'talked about what nobody wants to talk about.',
        url: VideoList.almamora,
        tipo: 'video',
        act: workalma,
    },
    {
        marca: "OLX",
        titulo: 'Selling it’s easy',
        desc_marca: 'OLX',
        desc: 'needed people’s used stuff to sell them.',
        url: VideoList.olx,
        tipo: 'video',
        act: workolx,
    },
    {
        marca: "FARMACITY",
        titulo: 'Health is also contagious',
        desc_marca: 'Farmacity',
        desc: 'talked about the most contagious thing in the world.',
        url: VideoList.farmacity,
        tipo: 'video',
        act: workfarmacity,
    },
    {
        marca: "BONOBON",
        titulo: 'Advice',
        desc_marca: '',
        desc: 'a candy needed people to stop giving it away and actually eat it.',
        url: VideoList.bonobon,
        tipo: 'video',
        act: workbonobon,
    }
  ]

const Works = () => {
  return (
    <Grid
        container 
        direction="row" 
        alignItems="start" 
        justifyContent="center" 
        rowSpacing={4} 
        columnSpacing={7}
        >
        {trabajos
            ? trabajos.map((d, i) => (
                <Grid container
                  key={`${d.titulo}-${i}`}
                  item 
                  xs={12} md={6}
                >
                    <Grid className='video fontbold' data-title={d.titulo}>
                        <Link color="inherit"
                                aria-label="menu"
                                component={RouterLink}
                                to={'/work'}   
                                state={{from: [d, trabajos] }}                        
                                >
                            <Grid className='stack'></Grid>
                            {
                                d.tipo === 'video' 
                                ? <Video urlvideo = {d.url}/>
                                : <Imagen url={d.url} titulo={d.titulo}/>
                            }
                        </Link>
                        <Grid>
                            <Typography variant="h5" sx={{ lineHeight: 1.1 }}> When
                                <CustomTypOb variant='h7'> {d.desc_marca} </CustomTypOb>                        
                                {d.desc}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                ))
            : 'Loading...'}
    </Grid>
  )
}

Works.propTypes = {}

export default Works

import React from 'react'
import { useEffect } from "react";
import PropTypes from 'prop-types'
import AppFrame from './AppFrame'
import { Grid, Typography, Box, Link } from '@mui/material'
import { styled, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CalibriFont from './../fonts/CalibriFont';
import { useLocation } from 'react-router-dom';
import VideoWork from './VideoWork';
import Imagen from './Imagen';
import { Link as RouterLink} from 'react-router-dom'
import {HiOutlineArrowLeft, HiOutlineArrowRight} from 'react-icons/hi';

const GridPNWorkD = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
}));

const GridPNWorkM = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
}));

const CustomTypO = styled(Typography)`
  color: #1c2dfc;

`;

const CustomTypB = styled(Typography)`
  color: black;
`;

const StyledLink = styled(Link)`
    color: inherit;
    &:hover {
    background: #1c2dfc;
    color: black;
    };
`;

function renderVideos(vids) {
    let videos;
    
    vids
        ? videos = (vids.map(( d, i ) => ( 
            <Grid pb={2} key={`${d}-${i}`}>
                <VideoWork urlvideo = {d}/>
            </Grid>
        )))
    : videos = null;

    return videos;
}

function renderImagenes(imgs, titulo) {
    let imagenes;

    imgs
        ? imagenes = (imgs.map(( d, i ) => ( 
            <Grid pb={2} key={`${d}-${i}`}>
                <Imagen url={d} titulo={titulo}/>
            </Grid>
        )))
    : imagenes = null;

    return imagenes;
}

function renderLinks(links) {
    let urls;

    links
        ? urls = (links.map(( d, i ) => ( 
            <Grid pb={1} key={`${d}-${i}`}>
                <CustomTypB lineHeight={1.1} className='fontbold'>
                        <StyledLink py={0.5}
                            fontFamily= 'Inter'
                            textTransform="none" 
                            variant="body2" 
                            href={d} 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener"
                            lineHeight={0}>
                        {d}
                        </StyledLink>
                    </CustomTypB>
            </Grid>
        )))
    : urls = null;

    return urls;
}

const WorkPage = props => {
    const location = useLocation()
    const { from } = location.state

    const actual = from[0];
    const trabajos = from[1];

    useEffect(() => {
        window.scrollTo(0, 0);
      });

   return(
        <AppFrame>
            <Grid container paddingX={{ xs: 3, sm: 6 }} paddingTop={{xs:10, sm:13}}>
                <Grid item xs={12} md={5} paddingRight={{ md: 5 }}>
                    <Grid container paddingBottom={{ xs: 4 }}>
                        <ThemeProvider theme={CalibriFont}>
                        <CssBaseline />
                            <Grid container pb={2}>
                                <CustomTypB className='fontbold' variant='h5' align='left' component="div">
                                    <Box sx={{ lineHeight: 1.4 }}> {actual.marca} </Box>
                                    <Box sx={{ lineHeight: 1.2 }}> {actual.titulo} </Box>
                                </CustomTypB>
                            </Grid>
                            <CustomTypB className='fontregular' variant='h7'>
                                <Box sx={{ lineHeight: 1.3 }} paddingBottom={2}>{actual.act.desc}</Box>
                                <Box sx={{ lineHeight: 1.3 }} >{actual.act.creat}</Box>
                                <Box sx={{ lineHeight: 1.3 }} >{actual.act.prod}</Box>
                            </CustomTypB>
                        </ThemeProvider>
                    </Grid>
                    <GridPNWorkD 
                        container 
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center">
                        <CustomTypO variant='h7' className='fontbold'>
                        {
                                (actual.marca !== "AB INBEV")
                                ? 
                                <Link paddingRight={4}
                                    color="inherit"
                                    aria-label="menu"
                                    component={RouterLink}
                                    to={'/work'}   
                                    state={{ from: [trabajos[trabajos.findIndex(work => work.titulo === actual.titulo)-1], trabajos] }}                        
                                    > 
                                    <HiOutlineArrowLeft fontSize="20"/>
                                </Link>
                                : ''
                            }
                            {
                                (actual.marca !== "BONOBON")
                                ? <Link color="inherit"
                                    aria-label="menu"
                                    component={RouterLink}
                                    to={'/work'}   
                                    state={{ from: [trabajos[trabajos.findIndex(work => work.titulo === actual.titulo)+1], trabajos] }}                        
                                    > 
                                    <HiOutlineArrowRight fontSize="20"/>
                                </Link>
                                : ''
                            }
                        </CustomTypO>
                    </GridPNWorkD>
                </Grid>
                <Grid item xs={12} md={7}>
                    {renderVideos(actual.act.vids)}
                    {renderImagenes(actual.act.imgs,actual.titulo)}
                    <Grid pt={2}>
                        {renderLinks(actual.act.links)}
                    </Grid>
                </Grid>
                <GridPNWorkM
                        container 
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center">
                        <CustomTypO variant='h7'
                        className='fontbold'
                        pt={3}>
                        {
                                (actual.marca !== "AB INBEV")
                                ? 
                                <Link paddingRight={4}
                                    color="inherit"
                                    aria-label="menu"
                                    component={RouterLink}
                                    to={'/work'}   
                                    state={{ from: [trabajos[trabajos.findIndex(work => work.titulo === actual.titulo)-1], trabajos] }}                        
                                    > 
                                    <HiOutlineArrowLeft fontSize="20"/>
                                </Link>
                                : ''
                            }
                            {
                                (actual.marca !== "BONOBON")
                                ? <Link color="inherit"
                                    aria-label="menu"
                                    component={RouterLink}
                                    to={'/work'}   
                                    state={{ from: [trabajos[trabajos.findIndex(work => work.titulo === actual.titulo)+1], trabajos] }}                        
                                    > 
                                    <HiOutlineArrowRight fontSize="20"/>
                                </Link>
                                : ''
                            }
                        </CustomTypO>
                    </GridPNWorkM>
            </Grid>
        </AppFrame>
    )
}

WorkPage.propTypes = {}

export default WorkPage
import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box';


const HomeIntro = props => {    
  return (
    <Grid container pt={{ xs:4, sm: 2 }}>
          <Typography className='introtext fontregular' align='left' component="div">
              <Box sx={{ lineHeight: 1.1 }}>Hi there. We are Ivan & Benja, a multicultural award-winning creative team from Argentina. +14 years working together. +4 years as freelance creatives. We led successful pitches across different global markets including Argentina, LATAM, Brazil, Mexico, USA, Europe, and MENA region. Collaborating with global advertising agencies to create tv commercials, integrated campaigns, activation ideas, branded content, you name it. For brands like these:</Box>
          </Typography>
    </Grid>
  )
}

HomeIntro.propTypes = {}

export default HomeIntro
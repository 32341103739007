import React from 'react'
import PropTypes from 'prop-types'
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { styled, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CalibriFont from './../fonts/CalibriFont'

const CustomizedLink = styled(Link)`
  font-family: 'Inter';
  font-size: 1.5em;
  color: #1c2dfc;
  font-weight: 700;
  @media screen and (max-width: 600px) {
    font-size: 1.1em;
  }
`;

const Navbar = props => {

  return (
    <Grid className='navbar'
    container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
      paddingTop={3}
      >
        <CustomizedLink
          underline="none"
          color="text.primary"
          href="/"
          px={2}
        >
          works
        </CustomizedLink>
        <CustomizedLink
          underline="none"
          color="text.primary"
          href="/about"
        >
          about
        </CustomizedLink>

    </Grid>
  )
}

Navbar.propTypes = {}

export default Navbar

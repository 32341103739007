import React from 'react'
import PropTypes from 'prop-types'
import AppFrame from './../components/AppFrame'
import HomePicture from './HomePicture'
import HomeIntro from './HomeIntro'
import { Grid } from '@mui/material'
import Works from './Works'

const MainPage = props => {
  return (
    <AppFrame>
      <Grid container className='zindex' padding={{ xs: 3, sm: 6, md: 10 }}>
        <HomeIntro/>
      </Grid>
      <Grid container paddingX={{ xs: 3, sm: 6, md: 10 }}>
        <Works/>
      </Grid>
    </AppFrame>
  )
}

MainPage.propTypes = {}

export default MainPage